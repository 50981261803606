<template>
  <div>
    <div class="modal-body">
      <div class="form-row">
        <div
          class="form-group col-md-6 required"
          :class="{ 'is-invalid': errors.has('name') }"
        >
          <label for="name_2_field" class="control-label text-capitalize"
            >Name:</label
          >
          <input
            id="name_2_field"
            v-model="user.name"
            v-validate="{ required: true }"
            type="text"
            name="name"
            class="form-control"
          />
          <span v-show="errors.has('name')" class="invalid-feedback">{{
            errors.first("name")
          }}</span>
        </div>
        <div
          class="form-group col-md-6"
          :class="{ 'is-invalid': errors.has('title') }"
        >
          <label for="title_2_field" class="control-label text-capitalize"
            >Title:</label
          >
          <input
            id="title_2_field"
            v-model="user.title"
            v-validate="{ required: false }"
            type="text"
            name="title"
            class="form-control"
            placeholder="Analyst"
          />
          <span v-show="errors.has('title')" class="invalid-feedback">{{
            errors.first("title")
          }}</span>
        </div>
        <div
          class="form-group col-md-6 required"
          :class="{ 'is-invalid': errors.has('role') }"
        >
          <label for="role" class="control-label text-capitalize">Role:</label>
          <select
            id="role"
            v-model="user.role"
            v-validate="{ required: true }"
            name="role"
            class="form-control"
            :disabled="disableRole"
          >
            <option v-for="role in roles" :key="role.id" :value="role.id">
              {{ role.name }}
            </option>
          </select>
          <span v-show="errors.has('role')" class="invalid-feedback">{{
            errors.first("role")
          }}</span>
        </div>
        <div
          class="form-group col-md-6 required"
          :class="{ 'is-invalid': errors.has('email') }"
        >
          <label for="email_2_field" class="control-label text-capitalize"
            >Email:</label
          >
          <input
            id="email_2_field"
            v-model="user.email"
            v-validate="{ required: true, email: true }"
            type="email"
            name="email"
            class="form-control"
          />
          <span v-show="errors.has('email')" class="invalid-feedback">{{
            errors.first("email")
          }}</span>
        </div>
        <div
          class="form-group col-md-6"
          :class="{ 'is-invalid': errors.has('store') }"
        >
          <label for="store" class="text-capitalize"
            >Store:</label
          >
          <select
            id="store"
            v-model="user.store"
            v-validate="{ required: false }"
            name="store"
            class="form-control"
            :disabled="disableStore"
          >
            <option :value="''">Select store</option>
            <option v-for="store in stores" :key="store.id" :value="store.id">
              {{ store.name }}
            </option>
          </select>
          <span v-show="errors.has('store')" class="invalid-feedback">{{
            errors.first("store")
          }}</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <ButtonLoading
        v-if="hasPermission('delete-users')"
        :loading="loadingDelete"
        :title="'Delete user'"
        :class="'btn btn-outline-danger mr-4'"
        v-on:click.native="submitDeleteForm"
      />
      <ButtonLoading
        :loading="loading"
        :title="'Save settings'"
        :class="'btn btn-secondary float-right'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Permission from "@/utils/permission";
import ButtonLoading from "./ButtonLoading.vue";

export default {
  name: "UserForm",
  components: {
    ButtonLoading,
  },
  extends: Permission,
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          id: null,
          name: null,
          title: null,
          role: "contributor",
          email: null,
        };
      },
      canChangeRole: {
        type: Boolean,
        default: false,
      },
    },
  },
  data() {
    return {
      loadingDelete: false,
      loading: false,
      roles: [],
      stores: [],
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    disableRole() {
      if (this.hasPermission("change-roles")) {
        return false;
      }
      return true;
    },
    disableStore() {
      if (this.hasPermission("change-stores")) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    jsonAPI.get("organization/roles").then((response) => {
      this.roles = response.data;
    });
    jsonAPI.get("store").then((response) => {
      this.stores = response.data.data;
    });
  },
  methods: {
    async submitDeleteForm() {
      this.loadingDelete = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          jsonAPI
            .delete("user/" + this.user.id, this.user)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "Users deleted",
                type: "success",
              });
              this.loadingDelete = false;
              this.$emit("deleteItem", this.user.id);
              this.closeModal();
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loadingDelete = false;
              window.Bus.$emit("flash-message", {
                text: "error main",
                type: "error",
              });
            });
        } else {
          this.loadingDelete = false;
        }
      });
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          jsonAPI
            .put("user/" + this.user.id, this.user)
            .then((response) => {
              if (this.user.id === this.getUser.id) {
                console.log(response.data);
                this.$store.commit("setUser", response.data);
              }
              window.Bus.$emit("flash-message", {
                text: "Users updated",
                type: "success",
              });
              this.loading = false;
              this.closeModal();
            })
            .catch((error) => {
              console.log(error);
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "error main",
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
